@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "roboto", sans-serif;
}

/* Header */
.navigation-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-menu li {
  margin: 0px 20px;
}

.navigation-menu li a {
  text-decoration: none;
  color: white;
}

.active {
  color: goldenrod !important;
  font-weight: bold !important;
}

/* mobile view */
.mobile-navigation {
  list-style: none;
}

.mobile-navigation li {
  margin-top: 20px;
}

.mobile-navigation li a {
  text-decoration: none;
  font-size: 1.4rem;
  color: black;
}

/* Home style */
.home {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-position: -120% 0; */
}

.headerContainer {
  width: auto;
  margin-left: 30px;
}

.home .headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: black;
}

.home .headerContainer p {
  margin-top: 15px;
  padding: 0;
  font-size: 40px;
  font-weight: lighter;
  color: black;
}

.headerContainer button {
  background-color: #121619;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  width: 180px;
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: #07090a;
  cursor: pointer;
  transition: 0.3s ease-in;
}

/* mobile view  */

@media only screen and (max-width: 600px) {
  .home {
    justify-content: center;
    align-items: center;
    background-size: cover;
  }

  .headerContainer {
    margin-left: 0;
    border-radius: 10px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #121619;
    color: white;
  }

  .home .headerContainer h1 {
    font-size: 35px;
    height: 30px;
    margin: 30px 0px;
    color: white;
  }

  .home .headerContainer p {
    font-size: 30px;
    color: white;
  }

  .headerContainer button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }

  .cart-length {
    left: 129px !important;
    padding-top: 0px !important;
  }
  .incre-decre {
    width: 55% !important;
  }
}


/* my css */

.addtoCart-btn {
  padding: 9px;
  background-color: #000;
  color: white;
  font-size: 17px;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.addtoCart-btn:hover {
  background-color: #fff;
  color: #000;
  transform: scale(105%);
}

.removetoCart-btn {
  padding: 9px;
  background-color: rgb(241, 233, 233);
  color: rgb(252, 38, 0);
  font-size: 17px;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: 1.5px solid red;
}

.removetoCart-btn:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
  transform: scale(105%);
}

.cart {
  padding: 5px 13px;
  position: relative;
}

.cart:hover {
  transform: scale(110%);
}

.cart-length {
  width: 30px;
  height: 25px;
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 30px;
  padding-top: 3px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.cart-length:hover {
  transform: translateY(-10px);
}

.menu-heading-div {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.menu-heading {
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  background-color: #000;
  color: gold;
  padding: 10px 15px;
  border-radius: 5px;
  -webkit-box-shadow: 1px 4px 9px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 4px 9px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 4px 9px 0px rgba(0, 0, 0, 0.75);
}

.cart-buying-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.incre-decre {
  width: 40%;
  background-color: rgb(244, 243, 243);
  margin-top: 15px;

}

.noitem-cart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 55px;
}

.noitem-cart img {
  width: 300px;
}